import { ICookieOptions, setCookie } from '@cian/utils/lib/browser/cookie';

// eslint-disable-next-line import/no-restricted-paths
import { getCookie } from '../../../browser/utils/cookie';
import { PROMO_SEARCH_TYPES_ONBOARDING_COOKIE_KEY } from '../../constants/promoSearchTypes';

export function getPromoSearchTypesOnboardingCookie(): boolean {
  if (typeof window !== 'undefined') {
    const cookie = getCookie(PROMO_SEARCH_TYPES_ONBOARDING_COOKIE_KEY);

    return cookie === '1';
  }

  return false;
}

export function setPromoSearchTypesOnboardingCookie() {
  const cookieConfig: ICookieOptions = { expires: 365 * 86400, secure: false };
  const isLocalhost = typeof window !== 'undefined' && window.location.hostname.indexOf('localhost') > -1;

  if (isLocalhost) {
    cookieConfig.domain = 'localhost';
  }

  setCookie(PROMO_SEARCH_TYPES_ONBOARDING_COOKIE_KEY, '1', cookieConfig);
}
