import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingConsultBannerContainer } from '../NewbuildingConsultBanner';
import { BrokerBannerContainer } from './BrokerBannerContainer';
import { isDiscountMortgageBannerEnabled } from '../../selectors/discountMortgage';
import { DiscountMortgageBannerContainer } from '../DiscountMortgageBannerContainer';
import { getIsBrokerBannerAvailable } from '../../selectors/broker';

export const BannerContainer = () => {
  const isBrokerBannerAvailable = useSelector(getIsBrokerBannerAvailable);
  const isDiscountMortgageBannerAvailable = useSelector(isDiscountMortgageBannerEnabled);

  if (isBrokerBannerAvailable) {
    return <BrokerBannerContainer />;
  }

  if (isDiscountMortgageBannerAvailable) {
    return <DiscountMortgageBannerContainer />;
  }

  return <NewbuildingConsultBannerContainer />;
};
