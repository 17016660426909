import { connect } from 'react-redux';

import { IApplicationState } from '../../../types/redux';
import { TTypedThunkDispatch } from '../../../types/thunk';
import { getOffersData } from '../../../actions/offersData';
import { updateTags } from '../../../actions/filterTags';
import {
  trackingActionPopupMoreParamOpen,
  trackingActionPopupMoreParamShowObjectsClick,
} from '../../../actions/analytics';
import { getPromoSearchTypes } from '../../../selectors/filters';
import { getBlackFridayActive } from '../../../selectors/newbuildingBlackFriday';
import { getDiscountMortgageEnabled } from '../../../selectors/discountMortgage';

import { MoreFiltersModalComponent, IModalStoreProps } from './MoreFiltersModal';

export function mapStateToProps(state: IApplicationState): IModalStoreProps {
  return {
    count: state.filters.offersCount.count,
    isCountFetching: state.filters.offersCount.isFetching,
    hasUndreground: state.region.currentRegion.hasMetro,
    canUseHiddenBase: state.user.isAuthenticated && state.user.permissions.canUseHiddenBase,
    isBlackFridayActive: getBlackFridayActive(state),
    discountMortgageEnabled: getDiscountMortgageEnabled(state),
    promoSearchTypesLoaded: Boolean(getPromoSearchTypes(state)),
  };
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    updateTags() {
      dispatch(updateTags());
    },
    trackingPopupMoreParamOpen() {
      dispatch(trackingActionPopupMoreParamOpen());
    },
    onSubmit: (canUseHiddenBase: boolean) => {
      dispatch(updateTags());
      dispatch(
        getOffersData({
          trackingAction: trackingActionPopupMoreParamShowObjectsClick,
          canUseHiddenBase,
        }),
      );
    },
  };
}

export const MoreFiltersModal = connect(mapStateToProps, mapDispatchToProps)(MoreFiltersModalComponent);
