import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { getPromoSearchTypesOnboardingEnabled } from '../../../selectors/filters';
import {
  getPromoSearchTypesOnboardingCookie,
  setPromoSearchTypesOnboardingCookie,
} from '../../../utils/promoSearchTypes';

interface IOnboardingTooltipResult {
  open: boolean;
  closeHandler: () => void;
}

export const usePromoSearchTypesOnboarding = (): IOnboardingTooltipResult => {
  const isOnboardingEnabled = useSelector(getPromoSearchTypesOnboardingEnabled);
  const isOnboardingShown = getPromoSearchTypesOnboardingCookie();

  const [open, setOpen] = useState(isOnboardingEnabled && !isOnboardingShown);

  const closeHandler = useCallback(() => {
    setOpen(false);
    setPromoSearchTypesOnboardingCookie();
  }, []);

  return {
    open,
    closeHandler,
  };
};
