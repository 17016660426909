/* eslint-disable max-lines */
import { ca } from '@cian/analytics';
import { getCPUrlFromNewbuildingGaLabel } from '../../utils/customPageUrl';
import { IThunkActionCreator } from '../../types/thunk';
import { createTrackingAction, roomstoFlatsMap } from './helpers';
import { IJsonQuery } from '../../types/interfaces';
// что бы держать аналитику в одном файле

// Переход в карточку ЖК по клику на заголовок
export const trackingActionToCardNewbuilding = createTrackingAction({
  category: 'Listing_JK',
  action: 'to_card_newbuilding',
  label: 'card_newobject_name',
});

// Переход в карточку застройщика
export const trackingActionToCardDeveloper = createTrackingAction({
  category: 'ListingJK',
  action: 'click_sopr',
  label: 'to_card_builder',
});

// Переход в листинг квартир в ЖК по клику на кол-во квартир от агентов
export const trackingActionClickAgentsFlats = createTrackingAction({
  name: 'oldevent',
  category: 'Listing_JK',
  action: 'click_agentsFlats',
  label: 'all',
});

// Переход в листинг квартир в ЖК по клику на квартиры определенной комнатности
// или на общее кол-во квартир от застройщика
export function trackingActionClickDevelopersFlats(rooms: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action: 'click_developersFlats',
      label: roomstoFlatsMap[rooms],
    });
  };
}

// Открытие списка телефонов (в случае, если их несколько)
export function trackingActionOpenContacts(gaLabelFull: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action: 'open_contacts',
      label: getCPUrlFromNewbuildingGaLabel(gaLabelFull),
    });
  };
}

// Добавление ЖК в избранное
export function trackingActionAddFromListingJG(
  gaLabelFull: string,
  action = 'add_from_listing_JK',
): IThunkActionCreator {
  return async () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'favorite',
      action,
      label: getCPUrlFromNewbuildingGaLabel(gaLabelFull),
    });
  };
}

// Удаление ЖК из избранного
export function trackingActionRemoveFromListingJK(
  gaLabelFull: string,
  action = 'remove_from_listing_JK',
): IThunkActionCreator {
  return async () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'favorite',
      action,
      label: getCPUrlFromNewbuildingGaLabel(gaLabelFull),
    });
  };
}

// Переход в карту (Клик На карте)
// TODO будет использовано в https://cianru.atlassian.net/browse/CD-36570
export const trackingActionMap = createTrackingAction({
  category: 'Skin',
  action: 'Map',
  label: 'Listing',
});

// Подписка на новые объекты в пустых ЖК
export const trackingActionSend = createTrackingAction({
  category: 'subscription',
  action: 'Send',
  label: 'From_empty_JK',
});

// Клик на похожие запросы (уточняющие запросы над выдачей)
export function trackingActionClickAlsoLooking(url: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action: 'click_alsoLooking',
      label: url,
    });
  };
}

// Открытие попапа с выбором ГЕО:
export const trackingActionPopupGeoOpen = createTrackingAction({
  category: 'Listing_JK',
  action: 'popup_GEO_open',
  label: '',
});

// При смене региона/локации через строку поиска
export function trackingActionRegionChange(typeOfNewValue: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'GeoSuggest',
      action: 'Change',
      label: `${typeOfNewValue}/yandex`, // пример: district/yandex
    });
  };
}

// При выборе через строку поиска
export function trackingActionSuggestionSelect(typeOfNewValue: string, typeOfSugges: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'GeoSuggest',
      action: 'Change',
      label: `${typeOfNewValue}/${typeOfSugges}`, // пример: district/yandex
    });
  };
}

// Открытие попапа метро или карты или района:
// TODO будет использовано в https://cianru.atlassian.net/browse/CD-36570
export function trackingActionPopupOpen(
  action: 'popup_metro_open' | 'popup_map_open' | 'popup_district_open',
): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action,
      label: '',
    });
  };
}

// Сохранение значений в попапе:
// TODO будет использовано в https://cianru.atlassian.net/browse/CD-36570
export function trackingActionPopupSave(
  action: 'popup_metro_save' | 'popup_map_save' | 'popup_district_save',
  count: number,
): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action,
      label: String(count),
    });
  };
}

// Открытие попапа с дополнительными параметрами (по клику на "Еще параметры"):
export const trackingActionPopupMoreParamOpen = createTrackingAction({
  category: 'Listing_JK',
  action: 'popup_more_param_open',
  label: '',
});

// Сохранение ГЕО в попапе:
export function trackingActionPopupGeoSave(queryString: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action: 'popup_GEO_save',
      label: queryString,
    });
  };
}

// Сохранение/применение доп параметров в попапе:
export function trackingActionPopupMoreParamShowObjectsClick(queryString: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action: 'popup_more_param_show_objects_click',
      label: queryString,
    });
  };
}

// Клик по кнопке показать объекты
export function trackingActionShowObjectsClick(queryString: string): IThunkActionCreator {
  return async () => {
    ca('event', {
      name: 'oldevent',
      category: 'Listing_JK',
      action: 'show_objects_click',
      label: queryString,
    });
  };
}

// Переход по ссылке "Лидеры рынка"
// TODO будет использовано в https://cianru.atlassian.net/browse/CD-36570
export const trackingActionClickAllLeaders = createTrackingAction({
  category: 'Listing_JK',
  action: 'click_all_leaders',
  label: '',
});

// Клик по кнопке "Узнать подробнее" в попапе проблемности в выдаче ЖК
export const trackProblemMoreButtonClick = (GKId: number) => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing_JK',
    action: 'JK_problems_more_click',
    label: `red/${GKId}`,
  });
};

// Ховер на иконку "Проблемно" в выдаче ЖК
export const trackProblemIconHover = (GKId: number) => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing_JK',
    action: 'JK_problems_icon_hover',
    label: `red/${GKId}`,
  });
};

// Форма подписки на акции показалась в экране.
export const trackPromoFormVisibility = () => {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Show',
    label: 'promotions_jk',
  });
};

// Произведена подписка на рассылку акций.
export const trackPromoFormSubscription = () => {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Send',
    label: 'promotions_jk',
  });
};

export const trackPromoSnippetShow = (newbuildingId: number, jsonQuery: IJsonQuery): IThunkActionCreator => {
  return async () => {
    ca('eventSite', {
      name: 'oldevent',
      category: 'ListingJK',
      action: 'show_sopr',
      label: 'promo_block',
      sc: jsonQuery,
      products: [
        {
          id: newbuildingId,
          offerType: 'JK',
        },
      ],
    });
  };
};
