import * as React from 'react';
import { connect } from 'react-redux';
import { IconPlaceholderBuilder16 } from '@cian/ui-kit-design-tokens/icons';
import { BuilderLabel } from '../../components/BuilderLabel';

import { SIZE_4, SIZE_8, SIZE_44, SIZE_60, SIZE_80, SIZE_AUTO } from '../../constants/sizes';

import { Link } from '../../components/Link';
import { Container } from '../../components/Container';
import { Image } from '../../components/Image';
import { IBuilder } from '../../types/newbuilding';
import { TTypedThunkDispatch } from '../../types/thunk';
import { trackingActionToCardDeveloper } from '../../actions/analytics';
import { NO_BREAK_SPACE } from '../../constants/symbolsUnicode';
import { BuilderName } from '../../components/BuilderName';

interface IBuilderInfoComponentOwnProps {
  builders: IBuilder[];
}

interface IBuilderInfoComponentDispatchProps {
  trackingToCardDeveloper(): void;
}

export type TBuilderInfoComponentProps = IBuilderInfoComponentOwnProps & IBuilderInfoComponentDispatchProps;

export function BuilderInfoComponent(props: TBuilderInfoComponentProps) {
  const { builders = [], trackingToCardDeveloper } = props;
  const isAloneBuilder = builders.length === 1;

  if (!builders.length) {
    return null;
  }

  return (
    <>
      {isAloneBuilder && (
        <div data-mark="AloneBuilder" data-testid="AloneBuilder">
          <Link url={builders[0].url} target="blank">
            <Container
              display="flex"
              borderRadius={2}
              padding={SIZE_8}
              borderColor="gray_0"
              borderStyle="solid"
              justifyContent="center"
              alignItems="center"
              borderTopWidth={1}
              borderRightWidth={1}
              borderBottomWidth={1}
              borderLeftWidth={1}
              width={SIZE_80}
              minHeight={SIZE_60}
            >
              <Container width={SIZE_AUTO}>
                {builders[0].logoUrl && builders[0].name ? (
                  <Image
                    alt={`Логотип застройщика ${builders[0].name}`}
                    src={`https://www.cian.ru${builders[0].logoUrl}`}
                    onClick={trackingToCardDeveloper}
                    noEmptyImage
                  />
                ) : (
                  <Container
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={SIZE_60}
                    height={SIZE_44}
                    backgroundColor="gray_0"
                  >
                    <IconPlaceholderBuilder16 />
                  </Container>
                )}
              </Container>
            </Container>
            <Container margin={`${SIZE_4} 0 ${SIZE_4} 0`} onClick={trackingToCardDeveloper}>
              <BuilderName name={builders[0].name} />
            </Container>
          </Link>
        </div>
      )}
      <BuilderLabel text="Застройщик" dataMark="BuilderLabel" data-testid="BuilderLabel" />
      {!isAloneBuilder && (
        <div>
          {builders.map((builder, index) => {
            const notLast = index + 1 < builders.length;

            return (
              <Link
                key={`${builder}${index}`}
                url={builders[index].url}
                target="blank"
                onClick={trackingToCardDeveloper}
              >
                <BuilderName name={`${builder.name}${notLast ? ',' : ''}${notLast ? NO_BREAK_SPACE : ''}`} />
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
}

export function mapDispatchToProps(dispatch: TTypedThunkDispatch) {
  return {
    trackingToCardDeveloper() {
      dispatch(trackingActionToCardDeveloper());
    },
  };
}
export const BuilderInfo = connect<null, IBuilderInfoComponentDispatchProps, IBuilderInfoComponentOwnProps>(
  null,
  mapDispatchToProps,
)(BuilderInfoComponent);
